.sliderContainer {
    align-items: center;
    background: #E3E3E3;
    display: flex;
    justify-content: center;
  }
  
  .slider {
    background: white;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.125);
    height: 35px;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
  }
  
  .slider::before,
  .slider::after {
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }
  
  .slider::after {
    right: 0;
    top: 0;
    transform: rotate(180deg);
  }
  
  .slider::before {
    left: 0;
    top: 0;
  }
  
  .slider .slide-track {
    animation: scroll 24s linear infinite;
    display: flex;
    width: calc(210px * 12);
  }
  
  .slider .slide {
    height: 100px;
    width: 210px;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-210px * 6));
    }
  }