@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Lobster&family=Roboto:wght@300;400&display=swap');

@font-face {
    font-family: 'Lobster'; /* Elige el nombre que desees para tu fuente */
    src: url('../public/Lobster-Regular.ttf') format('truetype'); /* Ajusta la ruta según tu estructura de carpetas */
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'IBMPlex'; /* Elige el nombre que desees para tu fuente */
    src: url('../public/IBMPlexSerif-Italic.ttf') format('truetype'); /* Ajusta la ruta según tu estructura de carpetas */
    font-weight: normal;
    font-style: normal;
}
*{
    box-sizing: border-box;

}
html {
    font-size: 16px;
}
body{
    background-color: rgb(211, 211, 211);
}
h1 {
    font-family: 'Lobster', sans-serif; 
  }
h2, h3{   
    font-family: 'IBMPlex', sans-serif;
}
p{
    font-family: 'IMBPlex';
}
figcaption{
    font-family: 'IMBPlex';   
}
